import bigBlob from "assets/images/roadmap-full-blob.png";
import roadmapCircle1 from "assets/images/roadmap-circle-1.svg";
import roadmapCircle2 from "assets/images/roadmap-circle-2.svg";
import roadmapCircle3 from "assets/images/roadmap-circle-3.svg";
import roadmapCircle4 from "assets/images/roadmap-circle-4.svg";
import roadmapCircle5 from "assets/images/roadmap-circle-5.svg";
import roadmapCircle6 from "assets/images/roadmap-circle-6.svg";
import roadmapCircle7 from "assets/images/roadmap-circle-7.svg";
import roadmapCircle8 from "assets/images/roadmap-circle-8.svg";
import roadmapCircle9 from "assets/images/roadmap-circle-9.svg";
import roadmapCircle10 from "assets/images/roadmap-circle-10.svg";
import roadmapCircle11 from "assets/images/roadmap-circle-11.svg";
import roadmapCircle12 from "assets/images/roadmap-circle-12.svg";

function Features() {
  return (
    <div className="relative">
      <img
        src={bigBlob}
        className="absolute top-[50%] left-0 w-full z-[-10] translate-y-[-50%]"
        alt=""
      />
      <div className="container">
        <h1
          className="title mb-12 sm:mb-20 md:mb-[80px]"
          // data-aos="fade-down"
          // data-aos-duration="1000"
        >
          Roadmap
        </h1>

        <div className="flex flex-col md:flex-row items-center md:space-x-[2vw]">
          <div className="flex-1 relative mb-[4vw] md:mb-0 md:mt-[10vw] mr-[8vw] md:mr-0">
            <img src={roadmapCircle1} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] translate-y-[-50%] md:translate-y-0 md:top-[150%] right-[110%] md:right-[unset] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              CORE TEAM LAYER I ESTABLISHMENT{" "}
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 ml-[8vw] md:ml-0">
            <img src={roadmapCircle2} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] md:top-[unset] bottom-[unset] translate-y-[-50%] md:translate-y-0 md:bottom-[150%] left-[120%] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              PARTNERSHIP WITH MAJOR WEB 3.0 COMPANIES
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 md:mt-[10vw] mr-[8vw] md:mr-0">
            <img src={roadmapCircle3} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] translate-y-[-50%] md:translate-y-0 md:top-[150%] right-[110%] md:right-[unset] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              MARKETPLACE CHARACTERIZATIONS
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 ml-[8vw] md:ml-0">
            <img src={roadmapCircle4} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] md:top-[unset] bottom-[unset] translate-y-[-50%] md:translate-y-0 md:bottom-[150%] left-[120%] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              DESIGNING A REVOLUTIONARY MIGRATION PATTERN FOR MUSIC COPYRIGHTSs
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 md:mt-[10vw] mr-[8vw] md:mr-0">
            <img src={roadmapCircle5} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] translate-y-[-50%] md:translate-y-0 md:top-[150%] right-[110%] md:right-[unset] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              CORE TEAM LAYER II STRENGTHENING LAYER s
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 ml-[8vw] md:ml-0">
            <img src={roadmapCircle6} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] md:top-[unset] bottom-[unset] translate-y-[-50%] md:translate-y-0 md:bottom-[150%] left-[120%] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              MARKETPLACE WIREFRAMING
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 md:mt-[10vw] mr-[8vw] md:mr-0">
            <img src={roadmapCircle7} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] translate-y-[-50%] md:translate-y-0 md:top-[150%] right-[110%] md:right-[unset] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              ALPHA LAUNCH CAMPAIGN KICK OFF
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 ml-[8vw] md:ml-0">
            <img src={roadmapCircle8} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] md:top-[unset] bottom-[unset] translate-y-[-50%] md:translate-y-0 md:bottom-[150%] left-[120%] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              ALPHA RELEASE
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 md:mt-[10vw] mr-[8vw] md:mr-0">
            <img src={roadmapCircle9} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] translate-y-[-50%] md:translate-y-0 md:top-[150%] right-[110%] md:right-[unset] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              DECENTRALIZATION COMPONENTS ARE ADDED GRADUALLY
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 ml-[8vw] md:ml-0">
            <img src={roadmapCircle10} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] md:top-[unset] bottom-[unset] translate-y-[-50%] md:translate-y-0 md:bottom-[150%] left-[120%] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              RELEASE OF NEW FEATURES
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 md:mt-[10vw] mr-[8vw] md:mr-0">
            <img src={roadmapCircle11} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] translate-y-[-50%] md:translate-y-0 md:top-[150%] right-[110%] md:right-[unset] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              CORE TEAM LAYER III SUPPORTING LAYER
            </p>
          </div>
          <div className="flex-1 relative mb-[4vw] md:mb-0 ml-[8vw] md:ml-0">
            <img src={roadmapCircle12} className="w-[16vw] md:w-full" alt="" />
            <p className="absolute top-[50%] md:top-[unset] bottom-[unset] translate-y-[-50%] md:translate-y-0 md:bottom-[150%] left-[120%] md:left-0 text-[2.4vw] md:text-[.8vw] w-[12em]">
              INNOVATION DEPARTMENT DEVELOPEMENT OF NEW FEATURES
            </p>
          </div>
        </div>
        {/* <img
          src={RoadMap}
          alt=""
          className="hidden md:block mt-[5rem] w-full"
          data-aos="fade-right"
          data-aos-duration="1000"
        />
        <img src={RoadMapMobile} alt="" className="block md:hidden" /> */}
      </div>
    </div>
  );
}

export default Features;
