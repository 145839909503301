import Button from "components/Button";
import React from "react";
import { Link } from "react-router-dom";
import welcomeBlobRight from "assets/images/welcome-blob.png";
import welcomeBlobLeft from "assets/images/welcome-blob-purple.png";
import welcomeAvatar from "assets/images/welcome-avatar-2.png";
import Contact from "./Contact";

function Welcome() {
  return (
    <div className="relative">
      <img
        src={welcomeBlobLeft}
        className="absolute top-[50%] left-[0%] translate-y-[-50%] z-[-10]"
        alt=""
      />
      <img
        src={welcomeBlobRight}
        className="absolute top-[50%] right-[0%] translate-y-[-50%] z-[-10]"
        alt=""
      />

      <div id="JoinUs" className="container">
        <div
          className="w-full sm:w-[40vw]"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h1 className="text-[4.6vw] sm:text-[3vw] mb-[3vw] text-white font-normal leading-[1.2]">
            Welcome to Halleluiah!
          </h1>

          <div className="w-full mb-[7vw] sm:mb-[3vw] max-w-[60%] sm:max-w-[30vw]">
            <Contact />
          </div>

          <p
            className="text-[2.4vw] sm:text-[1.4vw] text-white leading-[1.6] lg:leading-[1.6] sm:leading-[1.6] font-normal text-left sm:max-w-full 
          max-w-[60%] "
          >
            The world is created by people. If you have something to share, we’d
            love to hear from you. Find us on social media.
          </p>
        </div>

        <img
          src={welcomeAvatar}
          className="absolute top-[40%] right-[-12vw] sm:right-0 translate-y-[-50%] w-[66vw] sm:w-[40vw]"
          alt=""
        />
      </div>
    </div>
  );
}

export default Welcome;
