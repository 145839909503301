import Footer from "layouts/Footer";
import Navbar from "layouts/Navbar";
import React from "react";

function TermsOfUse() {
  return (
    <div>
      <Navbar />
      <div className="container mt-[10rem] mb-200px">
        <h1 className="title pb-4">Terms of Service</h1>

        <ol>
          <li>
            <strong>Introduction</strong>
            <ol>
              <li>
                Please read these Terms of Service (the &ldquo;
                <strong>Terms</strong>&rdquo;) carefully before using the
                website known as{" "}
                <a href="http://halleluiah.xyz">halleluiah.xyz</a> or mobile
                application (the &ldquo;<strong>Site</strong>&rdquo;, &ldquo;
                <strong>App</strong>&rdquo;&nbsp;and &ldquo;
                <strong>Service</strong>&rdquo;) operated by Uniquehorns Ltd.
                (&ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>
                &rdquo;, or &ldquo;<strong>our</strong>&rdquo;), DBA
                &ldquo;Halleluiah&rdquo;.&nbsp;
              </li>
              <li>
                Our Service serves as a platform (&ldquo;
                <strong>Platform</strong>&rdquo;) on which music artists
                (&ldquo;<strong>Artist</strong>&rdquo;) may post electronic
                records minted on a blockchain as a non-fungible token (&ldquo;
                <strong>NFT</strong>&rdquo;) linked to the Artist&rsquo;s
                percentage of the Streaming Royalties (as defined below) and
                users (&ldquo;<strong>user</strong>&rdquo;, &ldquo;
                <strong>you</strong>&rdquo;, and &ldquo;<strong>your</strong>
                &rdquo; ) can purchase the NFT from the Artist.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          BY USING OUR SERVICE, YOU AGREE TO THESE TERMS AND ALL OF THE TERMS
          INCORPORATED HEREIN BY REFERENCE<strong>&nbsp;</strong>INCLUDE A
          MANDATORY ARBITRATION AGREEMENT AND CLASS ACTION
          <strong>&nbsp;</strong>WAIVER. IF YOU DO NOT AGREE TO THESE TERMS, YOU
          MAY NOT ACCESS, DOWNLOAD OR USE THE SERVICE.
        </p>
        <ol>
          <ol>
            <li>
              We do NOT serve as a wallet provider, exchange, broker, financial
              institution, or creditor. The Platform facilitates transactions
              between Artists and Users; however, we are not a party to any
              agreement with Users or Artists.
            </li>
            <li>
              We may revise these Terms from time to time. We will identify the
              date the Terms were last updated at the beginning of the Terms.
              All changes are effective immediately when we post them (or such
              later effective date as may be indicated at the top of the revised
              Terms) and apply to all access to and use of the Service
              thereafter. Your continued use of the Service following the
              posting of the revised Terms means you accept and agree to the
              changes. It is your sole responsibility to check for updates to
              these Terms on an ongoing basis. If we make any material changes
              to these Terms, we will notify you and require your explicit
              consent before you will be able to continue to use the Service.
            </li>
          </ol>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Additional Terms</strong>
            <ol>
              <li>
                Our privacy policy at{" "}
                <a href="http://halleluiah.xyz">halleluiah.xyz</a> (the &ldquo;
                <strong>Privacy Policy</strong>&rdquo;), which is incorporated
                by reference into these Terms, explains how we treat your
                personal data and protect your privacy when you use our Service.
                By using our Service, you agree that we may use such data in
                accordance with its Privacy Policy. To learn more about our
                practices concerning your personal data, as a visitor to the
                Site or user of our Service, please visit and review our Privacy
                Notice at the Site.
              </li>
              <li>
                If you take part of in a transaction in order to purchase an NFT
                through our Service, you will be subject to our form of NFT
                Purchase and Assignment of Royalties Agreement (&ldquo;
                <strong>NPA Agreement</strong>&rdquo;). To learn more about the
                NPA Agreement please visit and review it at the Site.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Use of the Service</strong>
            <ol>
              <li>
                You agree to comply with all applicable laws and regulations
                when using the Service, and you may only use the Service for
                lawful purposes.
              </li>
              <li>
                The Service is only available to users who can use the Service
                as permitted under applicable law in the relevant jurisdictions.
                The Service is not authorized for use in certain other
                jurisdictions in which the United States has embargoed goods or
                has otherwise applied any economic sanctions.
              </li>
              <li>
                You must be an individual, at least 18 years old in order to use
                the Service. By using the Service, you warrant that you are 18
                years or older and understand your obligations under these
                Terms.
              </li>
              <li>
                We may require you to provide additional information and
                documents in certain circumstances, such as a regulatory
                requirement of &ldquo;Know Your Client&rdquo; form, in order to
                execute a transaction, or at the request of any government
                authority, as any applicable law or regulation dictates
                including laws related to anti-laundering of income obtained by
                criminal means, or for counteracting financing of terrorism, or
                to investigate a potential violation of these Terms. In such
                cases, at our sole discretion, we may disable your account and
                block your ability to access the Service until such additional
                information and documents are provided and processed by us. If
                you do not provide complete and accurate information in response
                to such a request, we may refuse to restore your access to the
                Service.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Our Proprietary Rights</strong>
            <ol>
              <li>
                We or our licensors own and reserve all rights, title, and
                interests in and to the Service and all software, and other
                items and content used to provide you with the Service,
                including, without limitation, its &ldquo;look and feel&rdquo;,
                logos, designs, text, graphics, pictures, video, information,
                data, and sound files (collectively, &ldquo;
                <strong>Proprietary Rights</strong>&rdquo;). Other than the
                rights explicitly granted to you to use the Service in
                accordance with these Terms - no title to or ownership of any
                Proprietary Rights related to the Service is transferred to you
                pursuant to these Terms. All rights not explicitly granted to
                you are reserved by us.
              </li>
              <li>
                &nbsp;In the event that you provide comments, suggestions or
                recommendations to us related to the Service (including, without
                limitation, regarding modifications, enhancements, improvements,
                or other changes) (collectively, &ldquo;
                <strong>Feedback</strong>&rdquo;), you hereby grant to us a
                worldwide, royalty-free, irrevocable, perpetual license to use
                and otherwise incorporate any Feedback provided.
              </li>
              <li>
                You may not (i) remove any copyright, trademark, or other
                proprietary notices from our Service; (ii) reproduce, modify,
                distribute, sell or lease any part of the Service; or (iii)
                reverse engineer or attempt to extract the source code of any
                Software, unless applicable laws prohibit these restrictions, or
                you have our written permission to do so.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Third-Party Content</strong>
            <ol>
              <li>
                References to any third-party products, services, processes, or
                other information on the Service, including links to websites or
                applications, do not constitute any endorsement by us. By
                clicking on links as aforesaid you are subject to the terms and
                conditions of a third party. We do not make any representations
                or warranties about this third-party content or links available
                in our Service, including any content associated with an NFT.
                You acknowledge sole responsibility for and assume all risk
                arising from, your use of all links to third-party websites and
                their content.
              </li>
              <li>
                Third-party products and content are the property of their
                respective owners and may not be copied or used without the
                permission of the applicable intellectual property rights
                holder.
              </li>
            </ol>
          </li>
        </ol>
        <p dir="rtl">
          <br />
        </p>
        <ol>
          <li>
            <strong>Your Content</strong>
            <ol>
              <li>
                The service may permit you to upload content, such as text,
                files, graphics, images, audio and video to our servers (&ldquo;
                <strong>User Content</strong>&rdquo;). You will retain ownership
                of all of your User Content (other than Feedback). However, by
                providing us with the User Content, you grant us, subject to our
                Privacy Policy, a non-exclusive, worldwide, transferrable,
                sub-licensable, royalty-free permanent license to process your
                User Content, without further notice to or consent from you, and
                without the requirement of payment to you or any other person or
                entity.
              </li>
              <li>
                You represent and warrant that you have all rights or authority
                necessary regarding the User Content on the Service. You
                represent and warrant that the User Content does not contain
                material subject to copyright or other intellectual property
                rights and that the content does not violate any laws. We may
                take down User Content in response to any violation of our Terms
                and policies or applicable law, at our sole discretion.
              </li>
              <li>
                Intellectual property infringement claims may terminate a
                user&apos;s access to the Service if the user is determined to
                be an infringer, at our sole discretion.
              </li>
              <li>
                By using our Service, the Artist agrees to grant us the right to
                use his name and image for marketing or promotional purposes. By
                using the Service, the Artist declares that he is responsible
                for the User Content of his NFT and that he does not infringe
                the intellectual property of others. The Artist understands that
                any NFT that violates any of our Terms and policies or
                applicable law may be obfuscated or deleted by us from the
                service, at our sole discretion.
              </li>
              <li>
                In addition, we may anonymize and copy, modify or create
                derivative works of your User Content (&ldquo;
                <strong>Our Work(s)</strong>&rdquo;) for the purposes described
                in the Privacy Policy. By accepting these Terms you hereby grant
                us a non-exclusive, worldwide, perpetual, irrevocable,
                transferable, sub-licensable, royalty-free license to use, in
                any way, your fully anonymized User Content used in Our Works,
                without further notice to or consent from you, and without the
                requirement of payment to you or any other person or entity.
              </li>
              <li>
                You hereby represent and warrant that: (i) the User Content, or
                (ii) your submission; or uploading; or otherwise make available
                to others the User Content, or (iii) our use of the User Content
                as permitted herein, will not infringe, misappropriate or
                violate any third party&rsquo;s intellectual property or
                proprietary rights, or rights of publicity or privacy, or result
                in the violation of any applicable law or regulation. You hereby
                acknowledge and agree that complying with all applicable laws
                and/or regulations regarding publicity and privacy while using
                the Service shall be solely under your responsibility and you
                will bear all liability for any consequences resulting from not
                complying with such laws and regulations. You hereby agree and
                warrant not to make available or share with others any User
                Content that can be reasonably understood to be defamatory,
                libellous, hateful, violent, obscene, pornographic, unlawful or
                otherwise offensive. Although we are not required to do so, we
                may access, review and delete all or part of your User Content
                at any time, including in order to assess and stop any violation
                of these Terms as determined by us in its sole discretion.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>NFT</strong>
            <ol>
              <li>
                An NFT exists only by virtue of the ownership record maintained
                in the associated blockchain network (the &ldquo;
                <strong>Blockchain</strong>&rdquo;). Any NFT that you purchase
                or sell, is governed by the NPA Agreement, a smart contract, and
                the Blockchain. We cannot control, change or cancel the transfer
                of the NFT and therefore we have no liability with respect to
                the execution of the NFT sale.
              </li>
              <li>
                The Platform does not store, send, or receive NFTs. Any transfer
                of NFTs occurs within the Blockchain and not on the Platform.
                Some transactions shall be deemed to be made when recorded on
                the Blockchain, which is not necessarily the date or time that
                you initiated the transaction.
              </li>
              <li>
                The NFT sale will be subject to additional terms as specified in
                the NPA agreement, which, inter alia, includes terms and
                benefits associated with a given NFT. The Artist and purchaser
                are entirely responsible for communicating, promulgating,
                agreeing to, and enforcing the NPA Agreement. The NPA agreement
                is solely between the Artist and the purchaser. Since we are not
                a part of the NPA agreement we shall have no liability to its
                content and execution.
              </li>
              <li>
                You acknowledge, As detailed in the NPA agreement, that the
                associated royalty payments that the Artist sells you (&ldquo;
                <strong>Streaming Royalties</strong>&rdquo;) shall be programmed
                to be self-executing via Blockchain smart contract system. While
                it is expected that owners of NFTs will receive payments of
                royalties, we cannot guarantee this outcome. We have no control
                or ability to direct the Streaming Royalties or to ensure that
                the NFT will generate Streaming Royalties. It shall be clarified
                that we do not obligate to collect or distribute the Streaming
                Royalties to Artists or Users.
              </li>
              <li>
                Purchasing an NFT via our Service provides you property rights
                to that NFT. It shall be clarified that such rights, do NOT
                include the ownership of the intellectual property rights in the
                User Content that was minted to an NFT.&nbsp;
              </li>
              <li>
                All transactions on the Platform, including minting, purchasing,
                or selling NFTs, are facilitated by smart contracts existing on
                the Blockchain. In order to validate every transaction on the
                Blockchain, payment of a fee is required (a &ldquo;
                <strong>Blockchain Fee</strong>&rdquo;). The certain value of
                the Blockchain Fee may change unpredictably (and with no limit),
                it is not refundable under any circumstance and is not in our
                control. It shall be clarified that we do not control the
                Blockchain nor can promise its operation. Similarly, we do not
                control the smart contract related to your NFT and it is your
                responsibility to prevent an unintended Blockchain Fee usage
                which, if not limited by you through its wallet interface, may
                inflate.&nbsp;
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Risks Disclaimer</strong>
            <ol>
              <li>
                Risks associated with NFT&rsquo;s acquired on our Platform may
                relate to any disposition of the NFT. As detailed&lrm; in
                Section 11 below, we do not make any representation regarding
                ownership, price-fairness, non-infringement, and merchantability
                of the NFT or regarding security, smart contract code, and
                wallets software that is used with relation to the Service. It
                is hereby be clarified that the operation of the Platform shall
                not be deemed to create a relationship with you that includes
                tendering of advice.
              </li>
              <li>
                By using the Service, you acknowledge that NFTs may be
                materially affected by activity out of our control, such as
                fluctuations in the price and regulatory actions and changes.
                Such activity may limit your ability to sell your NFT or access
                it on our Platform or on the associated the Blockchain. You
                hereby represent that you are knowledgeable in using Blockchain
                technology and in initiating NFTs transactions
              </li>
              <li>
                You further consent that any transaction carried through the
                Platform is final and there will be no refunds. Given the high
                risk of loss in acquiring NFTs, you should carefully consider
                whether creating, buying, or selling an NFT is suitable for your
                circumstances and financial condition.
              </li>
              <li>
                By using the Service you declare that you did your own research
                with respect to the risks associated with any given transaction
                and you have adequate knowledge, market sophistication,
                professional advice, and experience to make your own evaluation
                of the risks conducting the transaction via our Service.
              </li>
              <li>
                You are responsible to pay all taxes, including as a result of
                your ownership, purchase, sale, or creation of any NFT, imposed
                by any governmental authority, associated with your use of the
                Platform. You are solely responsible for determining what, if
                any, taxes apply to your transactions through the Platform, and
                we will not be responsible for determining the taxes that apply
                to such transactions.
              </li>
              <li>
                You acknowledge the Platform may experience cyber-attacks or
                technical difficulties that may cause interruptions to or delays
                on the Platform. You agree to accept the risk of any failure
                resulting from cyber-attacks, including denial of service
                attacks, Sybil attacks, spoofing, smurfing, malware attacks,
                consensus-based attacks or any unanticipated or heightened
                technical difficulties, and that we will not be accountable for
                any related losses including theft or complete loss of your NFT.
              </li>
              <li>
                We are not responsible for NFT losses due to Blockchain, smart
                contracts, wallets, or any other feature&rsquo;s errors,
                including changes to the protocol rules of the Blockchain (i.e.,
                &quot;<strong>Forks</strong>&quot;), or any other issue. You
                further acknowledge that we are not responsible for any of these
                risks and cannot be held liable for any losses that you
                experience while accessing the Platform. You agree to assume
                full responsibility for all of the risks of accessing and using
                and interacting with the Platform
              </li>
              <li>
                We will not be liable to you for any losses incurred because of
                your use of the electronic wallet, including but not limited to
                any losses arising from: (a) user error, such as forgotten
                passwords or incorrectly construed smart contracts or other
                transactions; (b) server failure or data loss; (c) corrupted
                wallet files; (d) unauthorized access or activities by third
                parties.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Regulation</strong>
            <ol>
              <li>
                Regulation of digital assets is currently undeveloped and likely
                to rapidly evolve, varies significantly among international,
                federal, state and local jurisdictions and is subject to
                significant uncertainty. Various legislative and executive
                bodies in many countries may in the future, adopt laws,
                regulations, or other actions, which may severely impact your
                NFTs. The Department of the Treasury, the Securities Exchange
                Commission, and the Commodity Futures Trading Commission (the
                &ldquo;<strong>CFTC</strong>&rdquo;), for example, have
                published guidance on the treatment of virtual currencies. The
                US IRS released guidance treating virtual currency as property
                that is not currency for US federal income tax purposes,
                although there is no indication yet whether other courts or
                federal or state regulators will follow this
                classification.&nbsp;
              </li>
              <li>
                Since the laws governing digital assets are still evolving, the
                tax treatment of digital assets is subject to change. As there
                is considerable uncertainty over the taxation of digital assets,
                we cannot guarantee that the NFT will not be subject to further
                taxation in the future.&nbsp;
              </li>
              <li>
                The regulation of non-currency use of Blockchain assets is also
                uncertain. The CFTC has publicly taken the position that certain
                blockchain assets are commodities, and the SEC has issued a
                public report stating federal securities laws require treating
                some Blockchain assets as securities. To the extent that a
                domestic government or quasi-governmental agency exerts
                regulatory authority over a Blockchain, exchange, or asset, your
                NFTs may be materially and adversely affected. Various foreign
                jurisdictions may adopt laws or directives that affect your
                NFTs. New or changing laws and regulations or interpretations of
                existing laws and regulations may materially and adversely
                impact the value of the currency in which your NFTs may be
                exchanged, the liquidity of your NFTs, the ability to access
                marketplaces or exchanges on which to trade your NFTs, and the
                structure, rights and transferability of your NFTs.
              </li>
              <li>
                YOU HEREBY ACKNOWLEDGE THAT THERE ARE RISKS AND UNCERTAINTIES
                NOT PRESENTLY KNOWN, OR WHICH ARE NOT SUMMARIZED ABOVE, THAT MAY
                ALSO HAVE AN ADVERSE EFFECT ON OUR PLATFORM AND ON YOUR NFT.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Updates, Suspension and Termination of the Service</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 10.1.We reserve the right, in our sole discretion,
                to make any necessary unscheduled changes, updates or
                enhancements to the Service at any time. We may add or remove
                functionalities or features, and we also reserve the right to
                suspend or terminate your access to the Service at any time in
                our sole discretion, with or without cause or notice, and
                without incurring liability of any kind. For example, we may
                suspend or terminate your access to or use of the Service due
                to: (a) an actual or suspected violation of these Terms; (b) use
                of the Service in a manner that may cause us to incur legal
                liability or disrupt others&rsquo; use of the Service; (c) the
                suspicion or detection of any malicious code, virus or other
                compromises of the security of the Service by you; (d) scheduled
                or recurring downtime; or unplanned technical problems and
                outages.
              </li>
              <li>
                &nbsp; &nbsp; 10.2.If, in our determination, the suspension
                might be indefinite, or we have elected to terminate your access
                to the Service you may no longer have access to the User
                Content. Nevertheless, where reasonably possible, we will give
                you reasonable advance notice and a chance to extract your User
                Content.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>No Warranty</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 11.1.WE PROVIDE THE SERVICE &ldquo;AS IS&rdquo;,
                &ldquo;WITH ALL FAULTS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; TO
                THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. WE MAKE NO
                &ndash; AND SPECIFICALLY DISCLAIMS ALL &ndash; REPRESENTATIONS
                OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY
                OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY THAT
                THE SERVICE WILL BE ERROR-FREE OR FREE OF HARMFUL COMPONENTS,
                THAT THE USER CONTENT YOU PROVIDE WILL BE SECURE OR NOT
                OTHERWISE LOST OR DAMAGED, OR ANY IMPLIED WARRANTY OF
                MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR
                PURPOSE, OR NON-INFRINGEMENT, AND ANY WARRANTY ARISING OUT OF
                ANY COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF THE
                SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE FOREGOING
                EXCLUSIONS. IN SUCH AN EVENT, SUCH EXCLUSION WILL NOT APPLY
                SOLELY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
              </li>
              <li>
                &nbsp; &nbsp; 11.2.WE DO NOT WARRANT THAT THE CONTENT ON THE
                SERVICE IS RELIABLE, ERROR-FREE. WE WILL NOT BE LIABLE FOR ANY
                LOSS FROM ANY ACTION TAKEN ON USER CONTENT, CONTAINED ON THE
                SERVICE. WE DO NOT WARRANT THAT THE SERVICE IS FREE OF VIRUSES
                OR OTHER HARMFUL COMPONENTS. WE CAN NOT GUARANTEE THE SECURITY
                OF USER CONTENT. BY USING THE SERVICE YOU ACCEPT THE INHERENT
                SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER
                THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF
                SECURITY.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Limitation of Liability</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 12.1.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                LAW, IN NO EVENT WE WILL, OUR OFFICERS, EMPLOYEES, AGENTS OR
                SUPPLIERS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT
                LIMITATION, DAMAGES FOR LOST DATA, PROFITS OR REVENUE), HOWEVER
                CAUSED, UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT
                LIMITATION, CONTRACT, TORT, WARRANTY, NEGLIGENCE OR OTHERWISE,
                EVEN IF WE HAVE BEEN ADVISED AS TO THE POSSIBILITY OF SUCH
                DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
                AGGREGATE LIABILITY OF US, ITS OFFICERS, EMPLOYEES, AGENTS OR
                SUPPLIERS, RELATING TO THE SERVICE WILL BE LIMITED TO THE
                GREATER OF USD 100 OR THE AMOUNT YOU PAID US, IF ANY, IN THE
                LAST 12 MONTHS FOR THE SERVICE. THE LIMITATIONS AND EXCLUSIONS
                APPLY REGARDLESS OF WHETHER THIS REMEDY DOES NOT FULLY
                COMPENSATE YOU FOR ANY LOSSES OR FAILS OF ITS ESSENTIAL PURPOSE.
                SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF INCIDENTAL,
                CONSEQUENTIAL OR OTHER DAMAGES. IN SUCH EVENT, THIS LIMITATION
                WILL NOT APPLY TO YOU TO THE EXTENT PROHIBITED BY APPLICABLE
                LAW.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Indemnification</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 13.1.To the extent permitted by law, you will
                defend, indemnify and hold us, our officers, employees, agents
                or suppliers harmless from any and all claims, demands, losses,
                liabilities and expenses (including costs and attorneys&apos;
                fees) made by any third party due to or arising out of (i) your
                use of the Service, (ii) your violation of these Terms, or (iii)
                your violation of any applicable law or the rights of any third
                party. We reserve the right, at your expense, to assume the
                exclusive defense and control of any matter for which you are
                required to indemnify us, and you agree to cooperate with our
                defense of such claims. You agree not to settle any such claim
                without our prior written consent. We will use reasonable
                efforts to notify you of any such claim or demand that is
                subject to your indemnification obligation.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Governing Law</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 14.1.These Terms shall be governed and construed
                in accordance with the laws of the ______________., without
                giving effect to any conflict or choice of law provisions.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Arbitration &amp; Class Action Waiver</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 15.1.By agreeing to these Terms, and to the extent
                applicable by local law, you also agree to arbitrate any and all
                claims against us pursuant to the Federal Arbitration Act and
                subject to the terms below. You and us agree that this Section
                12 will satisfy the &ldquo;writing&rdquo; requirement of the
                Federal Arbitration Act, and further agree, that notwithstanding
                any other provision of the Terms, the Federal Arbitration Act
                shall govern the interpretation and enforcement of this Section.
              </li>
              <li>
                &nbsp; &nbsp; 15.2.If a dispute arises between you and us
                regarding a claim, you and we agree to binding alternative
                dispute resolution pursuant to the then-current Commercial
                Arbitration Rules of the American Arbitration Association
                (&ldquo;AAA&rdquo;) and its Supplementary Procedures for
                Consumer Related Disputes (collectively, &ldquo;AAA
                Rules&rdquo;).
              </li>
              <li>
                &nbsp; &nbsp; 15.3.TO THE EXTENT POSSIBLE UNDER APPLICABLE LAW,
                ARBITRATION REGARDING YOUR CLAIM SHALL BE MANDATORY AND BINDING.
                NEITHER PARTY WILL HAVE THE RIGHT TO LITIGATE THAT CLAIM THROUGH
                A COURT. IN ARBITRATION, NEITHER PARTY WILL HAVE THE RIGHT TO A
                JURY TRIAL OR TO ENGAGE IN DISCOVERY, EXCEPT AS PROVIDED FOR IN
                THE AAA RULES.
              </li>
              <li>
                &nbsp; &nbsp; 15.4.NO CLASS ACTION, OR OTHER REPRESENTATIVE
                ACTION OR PRIVATE ATTORNEY GENERAL ACTION OR JOINDER OR
                CONSOLIDATION OF ANY CLAIM WITH A CLAIM OF ANOTHER PERSON OR
                CLASS OF CLAIMANTS SHALL BE PERMITTED.
              </li>
              <li>
                &nbsp; &nbsp; 15.5.All parties to the arbitration will have the
                right, at their own expense, to be represented by an attorney or
                other advocate of their choosing. The arbitration proceeding
                will be held in New York, New York (borough of Manhattan),
                unless you elect to proceed with a telephonic hearing or unless
                you and us agree to an alternative location. We will pay the
                initial filing fee for any arbitration, but the parties each
                shall otherwise bear their respective fees and expenses except
                as may be provided in the AAA Rules.
              </li>
              <li>
                &nbsp; &nbsp; 15.6.For a copy of the AAA Rules, to file a claim
                or for other information about the AAA, contact the AAA.
              </li>
              <li>
                &nbsp; &nbsp; 15.7.All determinations as to the scope,
                interpretation, enforceability and validity of these Terms shall
                be made final exclusively by the arbitrator, which award shall
                be binding and final. Judgment on the arbitration award may be
                entered in any court having jurisdiction.
              </li>
              <li>
                &nbsp; &nbsp; 15.8.This arbitration provision shall survive the
                termination of the Service or of these Terms. If any portion of
                this arbitration provision is deemed invalid or unenforceable,
                the remaining portions shall remain in force.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Jurisdiction &amp; Injunctive Relief</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 16.1.Subject to the provisions of Section 12
                (Arbitration and Class Action Waiver) and to any applicable law,
                you and us irrevocably consent to the exclusive jurisdiction and
                venue of the state or federal courts for New York, New York
                (borough of Manhattan), USA, for all disputes arising out of or
                relating to these Terms. Notwithstanding anything to the
                contrary in Section 12, either party may bring an action in such
                court for temporary injunctive relief to preserve the status quo
                or to enjoin a violation of these Terms until an arbitrator can
                be empanelled and determine whether such relief should continue.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Waiver &amp; Severability</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 17.1.Any failure by us to enforce any right or
                provision of these Terms will not be considered a waiver of
                those rights. If any provision of these Terms is held to be
                invalid, illegal or unenforceable for any reason by an
                arbitrator, court or other tribunal of competent jurisdiction,
                the provision shall be modified to the extent necessary to make
                it enforceable while, to the maximum extent possible, reflecting
                the intent of the parties, and, in any event, the remaining
                provisions of these Terms will remain in full force and effect.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li>
            <strong>Assignment &amp; Transfer</strong>
            <ol>
              <li>
                &nbsp; &nbsp; 18.1.We may assign, transfer or otherwise dispose
                of our rights and obligations under this contract, in whole or
                in part, at any time without notice. You may not assign this
                contract or transfer any rights to use the Service without our
                written permission.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfUse;
