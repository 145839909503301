import Footer from "layouts/Footer";
import Navbar from "layouts/Navbar";
import React from "react";

function CookiesPolicy() {
  return (
    <div>
      <Navbar />
      <div className="container mt-[10rem] mb-200px">
        <h1 className="title">Cookies Policy</h1>
      </div>
      <Footer />
    </div>
  );
}

export default CookiesPolicy;
