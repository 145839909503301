import React from "react";

function Button({
  title,
  className = "py-[0.4rem] sm:py-2 px-4 sm:px-6 xl:py-3 xl:px-9 ",
}: {
  title: string;
  className?: string;
}) {
  return (
    <button
      className={`button-h bg-btn-green rounded-[2vw] font-bold text-xs sm:text-sm xl:text-lg ${className}`}
    >
      {title}
    </button>
  );
}

export default Button;
