import React, { useState } from "react";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import axios from "axios";

const GOOGLE_API_KEY = "AIzaSyABvVq2IUouzFrDrccFGJJ1XA_d424DN84";
const GOOGLE_CLIENT_ID =
  "879756027721-srcu5qvlstogle1dueo35g7ht4pf6b80.apps.googleusercontent.com";
const SPREAD_SHEET = "14hilvsqC59y0D9WTSrjfxbtd_vtnWawXn9v0n2P2_xA";
const SCOPE = "https://www.googleapis.com/auth/spreadsheets";

function Contact() {
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const submitHandler = (e: any) => {
    e.preventDefault();
    setError("");
    if (!type) {
      setError("Please select if you are Music owner or Music Enthusiast");
      return;
    }
    if (!email) {
      setError("Please enter your email");
      return;
    }
    const date = new Date();
    const obj = { date, email, type };
    console.log(obj);
    axios
      .post(
        "https://sheet.best/api/sheets/e2e33ef0-08f9-4834-9c7b-d760e1e1ac22",
        obj
      )
      .then((res) => {
        setShowModal(true);
      });
  };

  return (
    <div>
      <div>
        {!showModal ? (
          <>
            <h1 className="text-base sm:text-2xl lg:text-4xl text-white font-normal mb-[7vw] sm:mb-[2.5vw]">
              Join us now.
            </h1>
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="space-x-[1.4vw] flex sm:items-center mb-[4vw] sm:mb-[1.8vw]">
                <Checkbox
                  title="Music Owner"
                  id="music_owner"
                  name="abc"
                  value="Music Owner"
                  onChange={(val) => setType(val)}
                />
                <Checkbox
                  title="Music Enthusiast"
                  id="music_enthusiast"
                  name="abc"
                  value="Music Enthusiast"
                  onChange={(val) => setType(val)}
                />
              </div>

              {/* <div className="flex">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                  className="w-full bg-white rounded-[2vw] rounded-r-none py-[1.1vw] px-[2vw] text-black font-semibold border-none outline-none text-[1.2vw]"
                />
                <Button
                  title="Send"
                  className="rounded-l-none px-[2.2vw] text-[1vw]"
                />
              </div> */}

              <div className="">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                  className="w-full bg-[rgba(0,0,0,.25)] rounded-[6vw] sm:rounded-[2vw] py-[2.6vw] px-[5vw] sm:py-[1.1vw] sm:px-[2vw] text-white font-medium border-none outline-none 
                  text-[3vw] sm:text-[1.2vw] shadow-[0px_4px_15px_rgba(255,255,255,0.13)]"
                />
                {error && (
                  <div className="mt-[1.2vw] text-red-500">
                    <p className="text-[3.2vw] sm:text-[1.1vw]">{error}</p>
                  </div>
                )}
                <Button
                  title="Send"
                  className="px-[5vw] py-[1.4vw] sm:px-[2.8vw] sm:py-[.7vw] rounded-[8vw] uppercase text-[3vw] sm:text-[1vw] mt-[3.4vw] sm:mt-[1.4vw]"
                />
              </div>

              {/* <div className="space-y-4 sm:space-y-0 sm:space-x-6 flex flex-col sm:flex-row sm:items-center justify-center mx-auto max-w-[8rem] sm:max-w-auto"> */}
            </form>
          </>
        ) : (
          <div className="text-center">
            <h2 className="title mb-6">Thanks for your application!</h2>
            <p>We'll be in touch soon!</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contact;
