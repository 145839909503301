import React from "react";
import moonpay from "assets/images/moonpay.png";
import convalent from "assets/images/convalent.png";
import magic from "assets/images/magic.png";
import confrenceblob from "assets/images/confrence-blob.png";
import confrenceblob2 from "assets/images/confrence-blob-2.png";

function ConferenceWith() {
  return (
    <div className="relative">
      <img
        src={confrenceblob}
        className="absolute top-[50%] left-[0%] translate-y-[-50%] z-[-10]"
        alt=""
      />

      <img
        src={confrenceblob2}
        className="absolute top-[50%] right-[0%] translate-y-[-50%] z-[-10]"
        alt=""
      />

      <div className="container relative">
        <div className="text-left mb-[3rem] sm:mb-[9rem]">
          <h1
            className="title mb-6 sm:mb-12"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            In conference with
          </h1>

          <div
            className="flex items-center justify-start space-x-6 space-y-2 flex-wrap"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <img
              src={moonpay}
              className="w-[22vw]  sm:w-[9rem] lg:w-[14rem]"
              alt=""
            />
            <img
              src={magic}
              className="w-[22vw] sm:w-[9rem] lg:w-[14rem]"
              alt=""
            />
            <img
              src={convalent}
              className="w-[22vw]  sm:w-[9rem] lg:w-[14rem]"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConferenceWith;
