import React from "react";

function TeamCard({
  img,
  title,
  subTitle,
}: {
  img?: any;
  title: string;
  subTitle?: string;
}) {
  return (
    <div
      className="mx-4 md:mx-[.8rem] my-[.8rem] flex flex-col items-center"
      data-aos="fade-down"
      data-aos-duration="1000"
    >
      {img ? (
        <img src={img} className="w-[8rem] md:w-[9rem] object-cover" alt="" />
      ) : (
        ""
      )}
      <p className="font-base font-medium text-white text-center">{title}</p>
      <p className="font-light text-white text-center md:text-sm text-xs">
        {subTitle}
      </p>
    </div>
  );
}

export default TeamCard;
