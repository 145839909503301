import Navbar from "layouts/Navbar";
import Hero from "layouts/Hero";
import ConferenceWith from "layouts/ConferenceWith";
import Highlights from "layouts/Highlights";
import Features from "layouts/Features";
import RoadMap from "layouts/RoadMap";
import Team from "layouts/Team";
import Welcome from "layouts/Welcome";
import Footer from "layouts/Footer";
import FAQ from "layouts/FAQ";
import Curation from "layouts/Curation";

function HomePage() {
  return (
    <div>
      <Navbar />
      <Hero />
      <div className="mb-200px">
        <Highlights />
      </div>
      <div className="md:mb-100px mb-10">
        <Curation />
      </div>
      <div className="mb-200px">
        <Features />
      </div>
      <div className="mb-200px">
        <RoadMap />
      </div>
      <div className="mb-200px">
        <Team />
      </div>
      <div className="mb-200px">
        <ConferenceWith />
      </div>
      <div className="black-linear pb-[5rem] md:pb-200px" id="applyNow">
        <Welcome />
      </div>
      <div className="bg-black">
        <FAQ />
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
