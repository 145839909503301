import Footer from "layouts/Footer";
import Navbar from "layouts/Navbar";
import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <Navbar />
      <div className="container mt-[10rem] mb-200px">
        <h1 className="title pb-4">Privacy Policy</h1>

        <p>
          <strong>Introduction&nbsp;</strong>
        </p>
        <p>
          We, Uniquehorns LTD, DBA &ldquo;Halleluiah&rdquo; (&ldquo;
          <strong>Halleluiah</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo;,
          &ldquo;<strong>us</strong>&rdquo; or &ldquo;<strong>our</strong>
          &rdquo;) highly value and respect your privacy. Please read carefully
          and make sure you (&quot;<strong>you</strong>&quot; or &quot;
          <strong>your</strong>&quot;) fully understand and agree with this
          privacy policy (&ldquo;<strong>Policy</strong>&rdquo;), which
          describes Halleluiah&rsquo;s practices with regards to the collection,
          processing, use and sharing of your personal data collected when you
          access and use our website (the &ldquo;<strong>Website</strong>
          &rdquo;), mobile application (the &quot;<strong>App</strong>&quot;),
          the services provided by us (which, together, are referred to as the
          &quot;<strong>Services</strong>&quot;). By visiting our website,
          installing or using our applications, or using any of our services you
          are expressing your consent to this Policy and its terms. If you do
          not agree to this Policy, please do not access, or otherwise use our
          Services or any information contained herein.
        </p>
        <p>
          Halleluiah provides a community marketplace for music
          &ldquo;NFT&rsquo;s&rdquo; (non-fungible tokens) where you can purchase
          and sell music NFT&rsquo;s. We offer community management as a service
          so that owners of music IP&apos;s, fans &amp; collectors can interact
          in a safe, responsible and transparent way.
        </p>
        <p>
          Although you are not legally required to provide us with any Personal
          Data (as defined below), please be aware that without it we will not
          be able to provide you with the full range or with the best experience
          when using our Services.
        </p>
        <p>
          For the purposes of this Privacy Notice, &ldquo;personal data&rdquo;
          is any information about a specific individual or that identifies or
          may identify a specific individual. In other words, it is any piece of
          information than can be linked to you.
        </p>
        <p>
          For the purposes of this Privacy Notice, the term &quot;process&rdquo;
          or &ldquo;processing&rdquo; means any use of personal data, including
          but not limited to the collection, recording, organization, storing,
          adaptation, alteration, transferring, making available, blocking,
          deletion or destruction of personal data.
        </p>
        <p>
          <strong>1. Personal Data We Collect</strong>
        </p>
        <p>
          <strong>A. Personal Data That You Share with Us&nbsp;</strong>
        </p>
        <p>
          When you use our Service, you may give us information directly (when
          you&rsquo;re setting up your account or when you contact Customer
          Support) such as:
        </p>
        <ul>
          <li>First name and last name or stage name</li>
          <li>Email address</li>
          <li>Digital wallet ID</li>
          <li>Payment information and payments history</li>
          <li>Profile picture (optional);</li>
          <li>
            Details about NFTs you bid on and purchase, and the amounts you pay
            for each NFT
          </li>
          <li>
            <br />
          </li>
        </ul>
        <p>
          <strong>
            If you are an artist, you register and provide to Us:&nbsp;
          </strong>
        </p>
        <ul>
          <li>First name and last name</li>
          <li>Company name</li>
          <li>Email address</li>
          <li>List of credits to music creation</li>
          <li>Digital wallet id</li>
          <li>
            Details about NFTs you bid on and purchase, and the amounts you pay
            for each NFT
          </li>
        </ul>
        <p>
          <strong>
            B. Data we collect from third parties and data collected
            automatically
          </strong>
        </p>
        <p>
          We may collect or receive information about you from other sources
          like third-party applications. For example, we may obtain your
          blockchain address from third-party wallet providers, that provide
          this to us, and certain other information you choose to share with
          those wallets providers. We may add this to the data we have already
          collected from or about you through our Service.&nbsp;
        </p>
        <p>
          We collect data from activity that is publicly visible and/or
          accessible on blockchains. This may include blockchain addresses and
          information regarding purchases, sales, or transfers of NFTs, which
          may then be associated with other data you have provided to us.&nbsp;
        </p>
        <p>
          Some information is collected automatically when using the Service.
          This data may include information such as your IP address, browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages, unique
          device identifiers and other diagnostic data.
        </p>
        <p>
          When you access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device you use, your mobile device unique ID,
          the IP address of your mobile device, your mobile operating system,
          the type of mobile Internet browser you use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that your browser sends whenever you
          visit the Site or when you access the Service by or through a mobile
          device.
        </p>
        <p>
          <strong>C. Cookies and other tracking technologies</strong>
        </p>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on our Site and store certain information. Tracking technologies used
          are beacons, tags, and scripts to collect and track information and to
          improve and analyze the Services.
        </p>
        <p>
          A cookie is a small code file placed on your device. You can instruct
          Your browser (or you operating system) to refuse all Cookies or to
          indicate when a Cookie is being sent. However, this action can prevent
          you from to using some parts of our Services.&nbsp;
        </p>
        <p>
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on your personal computer or mobile device
          when you go offline, while Session Cookies are deleted as soon as you
          close your Web browser. We use both Session and Persistent Cookies for
          the purposes set out below:
        </p>
        <p>
          <strong>Necessary Cookies</strong>
        </p>
        <p>Type: Session Cookies</p>
        <p>Administered by: Us</p>
        <p>
          Purpose: These Cookies are essential and necessary in order to provide
          You with services available through the Website and to enable you to
          use some of its features. They help to authenticate users and prevent
          fraudulent use of user accounts. Without these Cookies, the services
          that you have asked for cannot be provided, and We only use these
          Cookies to provide you with those services.
        </p>
        <p>
          <strong>Notice Acceptance Cookies</strong>
        </p>
        <p>Type: Persistent Cookies</p>
        <p>Administered by: Us</p>
        <p>
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </p>
        <p>
          <strong>Functionality Cookies</strong>
        </p>
        <p>Type: Persistent Cookies</p>
        <p>Administered by: Us</p>
        <p>
          Purpose: These Cookies allow to remember choices you make when you use
          the Website, such as account login details or language preference. The
          sole purpose of these Cookies is to provide you with a more personal
          experience and to save you the trouble of having to re-enter your
          preferences every time you use the Website.
        </p>
        <p>
          <strong>Tracking and Performance Cookies</strong>
        </p>
        <p>Type: Persistent Cookies</p>
        <p>Administered by: Third-Parties</p>
        <p>
          Purpose: These Cookies are used to track information about traffic to
          the Website and how users use the Website. The information gathered
          via these Cookies may directly or indirectly identify you as an
          individual visitor. This is because the information collected is
          typically linked to a pseudonymous identifier associated with the
          device you use to access the Website. We may also use these Cookies to
          test new pages, features, or new functionality of the Website to see
          how Our users react to them.
        </p>
        <p>
          <strong>2. How do We use your personal data&nbsp;</strong>
          <strong>&nbsp;</strong>
        </p>
        <p>
          We use your Personal Data as necessary for the performance of our
          Services; for complying with applicable law; and based on our
          legitimate interests in maintaining and improving our Services and
          offerings, understanding how our Services are used, for promotional
          marketing purposes and optimizing our marketing, customer service and
          support operations, and protecting and securing our users, ourselves
          and members of the general public.
        </p>
        <p>Specifically, we use Personal Data for the following purposes:</p>
        <ul>
          <li>
            To create end-users accounts and allow them to use the service.
          </li>
          <li>To authenticate the user as a user of our service.</li>
          <li>To operate, improve, and optimize our service.</li>
          <li>
            To process payments and/or other financial information to facilitate
            trading of NFTs using the service.&nbsp;
          </li>
          <li>To personalize our service to the users.</li>
          <li>
            To provide technical support and respond to users&rsquo; inquiries.
          </li>
          <li>To secure users&rsquo; account.</li>
          <li>
            To protect our rights and property in connection with our service.
          </li>
          <li>
            To prevent and/or prosecute fraud or potentially illegal activities
            and to enforce our terms of service.
          </li>
          <li>
            To comply with our legal obligations, resolve any disputes we may
            have with you or other users, and to enforce our agreements.
          </li>
          <li>
            To contact our users with general or personalized service-related
            messages (such as password-retrieval or billing); or with
            promotional messages (such as newsletters, special offers, new
            features etc.); and to facilitate, sponsor and offer certain events
            and promotions.
          </li>
          <li>
            To support and enhance our data security measures, including for the
            purposes of preventing and mitigating the risks of fraud, error or
            any illegal or prohibited activity.
          </li>
          <li>
            To create aggregated statistical data, inferred non-personal data or
            anonymized or pseudonymized data (rendered non-personal), which we
            or our business partners may use to provide and improve our
            respective services; and
          </li>
          <li>To comply with any applicable laws and regulations.</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>Data Retention</strong>
        </p>
        <p>
          We keep our users&rsquo; Personal Data for as long as their account is
          active or as we consider necessary for the purposes described in this
          Policy&nbsp;or by the applicable law. We keep visitors of our
          Website&rsquo;s Personal Data for as long as we reasonably need it in
          order to maintain and expand our relationship further and to provide
          them with our Services.
        </p>
        <p>
          We will also keep and use such Personal Data for as long as is
          required in order to comply with our legal and contractual
          obligations, or to protect ourselves from any potential disputes
          (i.e., as required by laws applicable to records and bookkeeping; in
          order to have proof and evidence concerning our relationship or our
          users&rsquo; use of our Services, including any recordings uploaded by
          them, should any legal issues arise following their discontinuance of
          use).
        </p>
        <p>
          Please note that unless required by applicable law, we will not be
          obligated to retain your data for any particular period and therefore,
          we are free to securely delete it for any reason and at any time, with
          or without notifying you.
        </p>
        <p>
          Your Personal Data may be maintained, processed and stored by Us and
          Our authorized affiliates and service providers.
        </p>
        <p>
          <strong>Transfer of Your Personal Data</strong>
        </p>
        <p>
          Your information, including Personal Data, is processed at the
          Company&rsquo;s operating offices and in any other places where the
          parties involved in the processing are located. It means that this
          information may be transferred to &mdash; and maintained processed on
          &mdash; computers located outside of Your state, province, country or
          other governmental jurisdiction where the data protection laws may
          provide a lower level of data protection measures than those in Your
          jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>Data Sharing</strong>
        </p>
        <p>
          We might share your Personal Data with the following third parties for
          the following respective purposes:
        </p>
        <ul>
          <li>
            Subsidiaries and Affiliated Companies:&nbsp;We share Personal Data
            internally within our family of companies, for the purposes
            described in this Policy. In addition, should We or any of our
            affiliates undergo any change in control or ownership, including by
            means of merger, acquisition or purchase of its assets, or will be
            found eligible for a governmental grant, your Personal Data may be
            shared with the parties involved in such event.
          </li>
          <li>
            Service Providers:&nbsp;We may engage with certain third party
            entities and individuals for the performance of services that are
            complementary to our Services and/or facilitate, or support our
            Services (for example, hosting and server co-location services, data
            analytics services, marketing and advertising services, data and
            cyber security services, fraud detection and prevention services,
            payment processing services, e-mail and SMS distribution and
            monitoring services, session recording, and our business, legal and
            financial advisors) (collectively, &ldquo;
            <strong>Service Providers</strong>&rdquo;). These Service Providers
            may have access to your Personal Data, depending on each of their
            specific roles and purposes in facilitating and enhancing our
            Services, and may only use it for such purposes.
          </li>
          <li>
            Government / Law Enforcement Officials: in response to a subpoena,
            search warrant or court order (or similar requirement), or in
            compliance with any applicable laws and regulations. Such disclosure
            or access may occur if we believe in good faith that we are legally
            compelled to do so and solely to the extent that we believe is
            strictly necessary to comply, or that disclosure is appropriate in
            connection with efforts to investigate, prevent, or take action
            regarding actual or suspected illegal activity, fraud, or other
            wrongdoing.
          </li>
          <li>
            Protecting Rights and Safety:&nbsp;We may share your Personal Data
            with others if we believe in good faith that this will help protect
            the rights, property or personal safety of Halleluiah, any of our
            users or any members of the general public.
          </li>
          <li>
            Other: We may share your Personal Data in additional manners,
            pursuant to your explicit approval, or if we are legally obligated
            to do so, or we have successfully rendered such data non-personal
            and anonymous. We may transfer, share or otherwise use non-personal
            and anonymous data in our sole discretion and without the need for
            further approval.
          </li>
        </ul>
        <p>
          <strong>Communications</strong>
        </p>
        <ul>
          <li>
            Service Communications:&nbsp;we may contact you with important
            information regarding our Services. For example, we may notify you
            (through any of the means available to us) of changes or updates to
            our Services, billing issues, service changes, reports,
            password-retrieval notices, etc. You will not be able to opt-out of
            receiving such service communications.
          </li>
          <li>
            Notifications and Promotional Communications:&nbsp;we may send you
            notifications concerning messages and other updates in your user
            account. We may also send you notifications about new features,
            offerings, events and special opportunities or any other information
            we think our users will find valuable. We may provide such notices
            through any of the contact means available to us (e.g. SMS or
            e-mail), through the Services, or through our marketing campaigns on
            any other sites or platforms.
          </li>
        </ul>
        <p>
          If you wish not to receive such promotional communications, you may
          notify us at any time by e-mailing us at{" "}
          <a href="mailto:info@halleluiah.xyz">
            [
            <strong>
              <em>Halleluiah contact e-mail</em>
            </strong>
            ]
          </a>{" "}
          or by following any instructions contained in the promotional
          communications you receive, if any.
        </p>
        <p>
          <strong>Data Security</strong>
        </p>
        <p>
          In order to protect your Personal Data held with us and our Service
          Providers, we are using industry-standard physical, procedural and
          electronic security measures. However, please be aware that regardless
          of any security measures used, we cannot and do not guarantee the
          absolute protection and security of any Personal Data stored with us
          or with any third parties.
        </p>
        <p>
          <strong>Data Subject Rights</strong>
        </p>
        <p>
          We respect your rights regarding the privacy of your Personal Data and
          make our best efforts to allow you to exercise your rights.
        </p>
        <p>
          Depending on the law in your jurisdiction, you may have the following
          rights:
        </p>
        <ul>
          <li>
            <strong>Request access to Your Personal Data</strong>. In some
            cases, you may be able access, update or request deletion of your
            Personal Data directly within your account settings.
          </li>
          <li>
            <strong>
              Request correction of the Personal Data that We hold about You
            </strong>
            . You may have the right to have any incomplete or inaccurate
            information We hold about you corrected.
          </li>
          <li>
            <strong>Object to processing of Your Personal Data</strong>. You may
            object to the processing of your Personal Data where Our processing
            is based on Our legitimate interest, or where We are processing your
            Personal Data for direct marketing purposes.
          </li>
          <li>
            <strong>Request to delete Your Personal Data</strong>. You have the
            right to ask Us to delete or remove Personal Data when there is no
            good reason for Us to continue processing it.
          </li>
          <li>
            <strong>Withdraw Your consent</strong>. You have the right to
            withdraw your consent on using your Personal Data. If you withdraw
            your consent, We may not be able to provide you with access to
            certain specific functionalities of the Service.
          </li>
        </ul>
        <p>
          If you wish to exercise any right you may have with regards to your
          Personal Data under laws applicable to Halleluiah&rsquo;s processing
          of your Personal Data, please contact us at{" "}
          <a href="mailto:info@halleluiah.xyz">
            [
            <strong>
              <em>Halleluiah contact e-mail</em>
            </strong>
            ]
          </a>
          .&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>Children&rsquo;s Privacy</strong>
        </p>
        <p>
          Our Services are not designed to attract children under the age of 18
          (a &ldquo;Child&rdquo; or &ldquo;Children&rdquo;).&nbsp;We do not
          knowingly collect Personal Data from Children and do not wish to do
          so. If we learn that a Child is using the Services, we will prohibit
          and block such use and will make all efforts to promptly delete any
          Personal Data stored with us with regards to such Child.
        </p>
        <p>
          If you believe that we might have any such data, please contact us at{" "}
          <a href="mailto:info@halleluiah.xyz">
            [
            <strong>
              <em>Halleluiah contact e-mail</em>
            </strong>
            ]
          </a>
          .&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>Updates</strong>
        </p>
        <p>
          Updates and amendments: &nbsp;We may need to update this Policy. We
          will post all revisions, along with their effective dates. We
          recommend that you check this page every so often to make sure
          you&rsquo;re familiar with the latest Policy. We will ask for your
          consent to this Policy changes if required by applicable law; in other
          cases, please note that your continued use of the Service after any
          change means that you agree with, and consent to be bound by, the new
          Policy.
        </p>
        <p>
          <strong>Links to other websites through External links</strong>
        </p>
        <p>
          While our Services may contain links to other websites or services, we
          are not responsible for their privacy practices, and encourage you to
          pay attention when you leave our Services and to read the privacy
          policies and/or notices of each and every website and service you
          visit. This Policy applies only to Our Services.
        </p>
        <p>
          <br />
        </p>
        <p>
          Questions, Concerns or Complaints: If you have any comments or
          questions about this Policy or if you have any concerns regarding your
          privacy, please contact us at{" "}
          <a href="mailto:info@halleluiah.xyz">
            [
            <strong>
              <em>Halleluiah contact e-mail</em>
            </strong>
            ]
          </a>
          .
        </p>
        <p>
          General, Governing Law and Jurisdiction: We reserve the right to
          discontinue or modify any aspect of the Services at any time. This
          Policy, together with our Terms of Service and any other legal notices
          published by us on our website and in the App, shall constitute the
          entire agreement between us concerning the Services. If any provision
          of this Policy is deemed invalid by a court of competent jurisdiction,
          the invalidity of such provision shall not affect the validity of the
          remaining provisions of this Policy, which shall remain in full force
          and effect, provided, however, that in such event this Policy shall be
          interpreted so as to give effect, to the greatest extent consistent
          with, and permitted by, applicable law, to the meaning and intention
          of the excluded provision as determined by such court of competent
          jurisdiction.
        </p>
        <p>
          <strong>How to Contact Us</strong>
        </p>
        <p>
          If you have any questions about this Privacy Policy, or in case that
          you want to access or rectify your Personal Data (in accordance with
          the applicable law), You can contact us via at{" "}
          <a href="mailto:info@halleluiah.xyz">
            [<em>Halleluiah contact e-mail</em>]
          </a>
          .
        </p>
        <p>
          <strong>CCPA &amp; GDPR</strong>
        </p>
        <p>
          <br />
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
