import React from "react";
import curation from "assets/images/curation.png";

function Curation() {
  return (
    <div className="container flex items-center space-x-[0vw] sm:space-x-[3vw]">
      <div data-aos="fade-right" data-aos-duration="1000">
        <h1 className="text-[4vw] sm:text-[2.4vw] mb-[2.2vw]">
          Halleluiah’s Curation
        </h1>

        <div className="hidden sm:block">
          <p className="text-sm text-[1.3vw] leading-[1.7] xl:leading-[2.1vw] text-white opacity-[.7] font-normal mb-[2vw]">
            Algorithms keep backing limited songs while sidelining millions of
            alternative voices. Halleluiah’s curation is a blending between
            community, algorithmic and human curation.
          </p>
          <p className="text-sm text-[1.3vw] leading-[1.7] xl:leading-[2.1vw] text-white opacity-[.7] font-normal">
            While each of these mechanisms on its own tends to become truculent,
            their Shatnez provides a remedy to one of the biggest pains of
            today’s music industry.
          </p>
        </div>

        <p className="text-sm text-[2.7vw] leading-[1.7] xl:leading-[2.1vw] text-white opacity-[.8] font-normal mb-[2vw] sm:hidden">
          Halleluiah's curation combines community, algorithmic, and human
          curation. This synthesis provides a remedy for one of the biggest
          pains in today's music industry.
        </p>
      </div>
      <img
        src={curation}
        className="w-[50%] sm:w-[46%]"
        alt=""
        data-aos="fade-left"
        data-aos-duration="1000"
      />
    </div>
  );
}

export default Curation;
