import Footer from "layouts/Footer";
import Navbar from "layouts/Navbar";

function Manifest() {
  return (
    <div>
      <Navbar />
      <div className="container mt-[10rem] mb-200px">
        <h1 className="title">Halleluiah’s Ten commandments</h1>
        <br />
        <ul className="text-lg	ml-5">
          <li className="pb-2">Halleluiah, music shall set you free</li>
          <li className="pb-2">
            Thou shalt not upload your music on oppressing platforms
          </li>
          <li className="pb-2">
            Thou shalt not release music not from thy heart
          </li>
          <li className="pb-2">Thou shalt remember your voice has value</li>
          <li className="pb-2">
            Thou shalt remember the community that encouraged your gifts
          </li>
          <li className="pb-2">Respect thy community you came from</li>
          <li className="pb-2">
            Thou shalt not profit at the expense of other creators
          </li>
          <li className="pb-2">
            Thou shalt not create collaborations outside thy heart
          </li>
          <li className="pb-2">
            Thou shalt not use inspiration without fair retribution
          </li>
          <li className="pb-2">Thou shalt never again mute thy voice</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Manifest;
