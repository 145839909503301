import { useEffect } from "react";
import HomePage from "pages/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsOfUse from "pages/TermsOfUse";
import PrivacyPolicy from "pages/PrivacyPolicy";
import CookiesPolicy from "pages/CookiesPolicy";
import Manifest from "pages/Manifest";
import ScrollToTop from "components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/manifest" element={<Manifest />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
