import React from "react";

function Checkbox({
  title,
  name,
  id,
  value,
  onChange,
}: {
  title: string;
  name?: string;
  id?: string;
  value?: string;
  onChange: (val: string) => void;
}) {
  const handleClick = (e: any) => {
    // 👇️ get checkbox value with ref
    onChange(title);
  };
  return (
    <div
      className={"checkbox flex items-center space-x-[1.8vw] sm:space-x-[1vw]"}
    >
      <div>
        <input
          type={"radio"}
          name={name}
          id={id}
          value={value}
          onChange={handleClick}
        />
      </div>
      <label
        htmlFor={id}
        className="w-max cursor-pointer flex items-center text-[2.6vw] sm:text-[1vw]"
      >
        {title}
      </label>
    </div>
  );
}

export default Checkbox;
