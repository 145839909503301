import React from "react";
import feature1 from "assets/images/feature1.png";
import feature2 from "assets/images/feature2.png";
import DJ from "assets/images/DJ.png";

function Features() {
  return (
    <div className="relative">
      <div className="container">
        <h1
          className="title mb-6 sm:mb-12"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          Featured In
        </h1>

        <div
          className="flex flex-row space-x-4 xl:space-x-8"
          style={{ filter: "grayscale(100%)" }}
        >
          <button
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
            className="flex-1 md:flex-none sm:w-fit"
          >
            <img
              className="w-full md:w-[8rem] xl:w-[12.0625rem] shadow-whitish rounded-lg bg-black"
              src={feature1}
              alt=""
            />
          </button>
          <button
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="400"
            className="flex-1 md:flex-none sm:w-fit"
          >
            <img
              className="w-full md:w-[8rem] xl:w-[12.0625rem] shadow-whitish rounded-lg"
              src={feature2}
              alt=""
            />
          </button>
          <button
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="600"
            className="flex-1 md:flex-none md:w-fit py-6 flex items-center shadow-whitish bg-black rounded-lg justify-center"
          >
            <img
              src={DJ}
              className="w-full md:w-[8rem] xl:w-[12.0625rem]"
              alt=""
            />
          </button>
          {/* <button className="w-fit py-6 flex items-center shadow-whitish bg-black rounded-lg justify-center px-6">
            <img
              src={HAAR}
              className="w-[8rem] md:w-[12rem] xl:w-[15.4375rem]"
              alt=""
            />
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Features;
