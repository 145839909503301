import React from "react";

function HightlightCard({ title, desc }: { title: string; desc: string }) {
  return (
    <div
      className="hightlight-card rounded-xl sm:rounded-3xl py-7 px-5 sm:px-6 xl:px-15"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h1
        className="
       lg:text-2xl text-white mb-4"
      >
        {title}
      </h1>
      <p className="text-sm leading-[1.7] text-white">{desc}</p>
    </div>
  );
}

export default HightlightCard;
