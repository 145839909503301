import React from "react";
import heroBoy from "assets/images/hero-boy.png";
import heroBlob from "assets/images/hero-blob-2.png";
import responsiveheroblob from "assets/images/responsive-hero-blob.png";

function Hero() {
  return (
    <div className="relative">
      <img
        src={heroBlob}
        className="hidden sm:block absolute z-[-10] w-[100%] xl:w-[90%] md:top-[46%] left-[50%] translate-x-[-50%] translate-y-[-50%] top-[65%]"
        alt=""
      />
      <img
        src={responsiveheroblob}
        className="block sm:hidden absolute z-[-10] w-[100%] xl:w-[90%] md:top-[46%] left-[50%] translate-x-[-50%] translate-y-[-50%] top-[65%]"
        alt=""
      />
      <div className="container relative py-[.2rem] sm:py-0 flex items-center lg:min-h-screen justify-end ">
        <div
          className="sm:w-[50vw] absolute top-[50%] left-0 translate-y-[-50%] sm:static sm:translate-y-[unset]"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h2 className="w-[14em] sm:w-auto mb-[2.2vw] text-[3.2vw] capitalize sm:uppercase sm:text-[1.4vw] lg:leading-tight md:leading-tight xl:leading-tight text-white">
            Music is everywhere, without it life is mute.
          </h2>
          <h1 className="text-[4.2vw] sm:text-[3.6vw] leading-[1.2] lg:leading-tight md:leading-tight xl:leading-tight  text-white">
            HALLELUIAH,
            <br />
            INTRODUCING MUSIC
            <br />
            TO THE{" "}
            <div className="glitch inline">
              <span aria-hidden="true">metaverse</span>
              metaverse
              <span aria-hidden="true">metaverse</span>
            </div>
          </h1>
        </div>
        <img
          src={heroBoy}
          className="w-[40vw] sm:w-[30vw]"
          alt=""
          data-aos="fade-left"
          data-aos-duration="1000"
        />
      </div>
    </div>
  );
}

export default Hero;
