import TeamCard from "components/TeamCard";
import React from "react";
import team1 from "assets/images/team1.png";
import team2 from "assets/images/team2.png";
import team3 from "assets/images/team3.png";
import team5 from "assets/images/team5.png";
import team6 from "assets/images/team6.png";
import team8 from "assets/images/team8.png";
import team9 from "assets/images/team9.png";
import team10 from "assets/images/team10.png";
import teamP from "assets/images/team-p.png";
import teamG from "assets/images/team-g.png";
import teamBlob from "assets/images/team-blob.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

function Team() {
  return (
    <div className="relative ">
      <div className="block md:hidden">
        <img
          src={teamP}
          className="absolute w-full  left-[50%] translate-x-[-50%] translate-y-[-50%] z-[-1]"
          alt="Img"
        />
        <img
          src={teamG}
          className="absolute w-full top-[50%] left-[50%] translate-x-[-50%] translate-y-[-00%] z-[-1]"
          alt="Img"
        />
      </div>
      <img
        src={teamBlob}
        className="hidden md:block w-full absolute top-[50%] translate-y-[-50%] z-[-1]"
        alt=""
      />
      <div className=" relative">
        <div className="container">
          <h1
            className="title mb-1 sm:mb-12"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            Team
          </h1>
        </div>

        <div className="container">
          <div className="hidden md:flex justify-center flex-wrap">
            <TeamCard
              img={team1}
              title="Bezalel E Raviv"
              subTitle="Founder & CEO"
            />
            <TeamCard
              img={team2}
              title="Yorik Ben David"
              subTitle="Co-founder"
            />
            <TeamCard img={team3} title="Hilla Srour" subTitle="COO" />
            <TeamCard
              img={team5}
              title="Asher Bitansky"
              subTitle="Special Advisor"
            />
            <TeamCard
              img={team6}
              title="Nir Asaf"
              subTitle="VP of Technology"
            />

            <TeamCard
              img={team8}
              title="Natalie Tkhir"
              subTitle="Design Specialist"
            />
            <TeamCard
              img={team10}
              title="Bruna Brambatti"
              subTitle="Community Manager"
            />
            <TeamCard
              img={team9}
              title="Tom Wegner"
              subTitle="VP of PR & Strategy"
            />
          </div>
        </div>

        <div className="block md:hidden">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={0}
            className="teamswiper"
          >
            <SwiperSlide>
              <TeamCard
                img={team1}
                title="Bezalel E Raviv"
                subTitle="Founder & CEO"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeamCard
                img={team2}
                title="Yorik Ben David"
                subTitle="Co-founder"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeamCard img={team3} title="Hilla Srour" subTitle="COO" />
            </SwiperSlide>
            <SwiperSlide>
              <TeamCard
                img={team5}
                title="Asher Bitansky"
                subTitle="Special Advisor"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeamCard
                img={team6}
                title="Nir Asaf"
                subTitle="VP of Technology"
              />
            </SwiperSlide>

            <SwiperSlide>
              <TeamCard
                img={team8}
                title="Natalie Tkhir"
                subTitle="Design Specialist"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeamCard
                img={team10}
                title="Bruna Brambatti"
                subTitle="Community Manager"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TeamCard
                img={team9}
                title="Tom Wegner"
                subTitle="VP of PR & Strategy"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Team;
