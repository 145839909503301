import React, { useEffect, useRef, useState } from "react";
import { FaChevronUp } from "react-icons/fa";

interface IProps {
  open?: boolean;
  header: string | React.ReactNode;
  headerClassName?: string;
  titleClassName?: string;
  iconButtonClassName?: string;
  contentClassName?: string;
  contentContainerClassName?: string;
  collapsibleClassName?: string;
  children?: any;
}

const Collapsible: React.FC<IProps> = ({
  open,
  collapsibleClassName = "collapsible-card-edonec",
  headerClassName = "collapsible-header-edonec",
  titleClassName = "title-text-edonec",
  iconButtonClassName = "collapsible-icon-button-edonec",
  contentClassName = "collapsible-content-edonec",
  contentContainerClassName = "collapsible-content-padding-edonec",
  children,
  header,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(
    open ? undefined : 0
  );
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);
  return (
    <>
      <div className={collapsibleClassName}>
        <div>
          <div className={headerClassName}>
            <div className={`${titleClassName} text-[.8em] sm:text-[1em]`}>
              {header}
            </div>
            <button
              type="button"
              onClick={handleFilterOpening}
              className={iconButtonClassName}
            >
              <span
                className={`transition-all flex duration-[.3s] ${
                  isOpen ? "rotate-[0deg]" : "rotate-[180deg]"
                }`}
              >
                <FaChevronUp />
              </span>
            </button>
          </div>
        </div>
        <div className={`${contentClassName}`} style={{ height }}>
          <div ref={ref}>
            <div className={contentContainerClassName}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

function FAQ() {
  return (
    <div className="relative" data-aos="fade-down" data-aos-duration="1000">
      <div className="pb-[80px]">
        <div className="container relative ">
          <h1 className="title mb-6">Q&A</h1>
          <div className="mb-6 sm:mb-12">
            <Collapsible
              header="Musicians and Copyright Owners Ask
"
            >
              <div className="mr-4">
                <Collapsible header="Why create a Music NFT?">
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    Halleluiah's marketplace allows copyright owners to turn
                    their music into NFTs and have their songs inside any
                    Metaverse while deciding how much they want to get paid per
                    "stream." Can you imagine having your song played inside The
                    Sandbox, Decentraland, PUBG, or Fortnite and knowing and
                    deciding how much each play will cost?! Throughout the past
                    12 months, we have developed an infrastructure solution to
                    connect every song from the global music library to the
                    metaverse so that your existing music can reach a new
                    audience and generate a new revenue stream for you.
                  </p>
                </Collapsible>
                <Collapsible header="Why should I choose to create my Music NFT at Halleluiah?">
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    At Halleluiah, copyright owners have control over their
                    creations. Currently, the biggest revenue from the music
                    industry is generated through mechanical rights, aka
                    streams. These mechanics rights, though, give little or no
                    control over the song to the artist, let alone the fact that
                    the creator is the one to get the smallest share of the
                    profits. With our PlayBank, artists can be paid fairly for
                    their work since they are the ones to choose how many plays
                    one song can have and how much each play will be worth.
                  </p>
                </Collapsible>
                <Collapsible header="How to know if my music is copyrighted?">
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    We are delighted to inform you that you own the copyright if
                    you have written, composed, performed, or played a song.
                    When you turn your song into an NFT through Halleluiah, you
                    will still be the owner of your copyright.
                  </p>
                </Collapsible>
                <Collapsible
                  header="Do NFT creators get royalties?
"
                >
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    Yes, they do. In the real world, labels and major publishing
                    companies are the first ones to get paid, especially when
                    talking about streaming revenue and mechanical royalties
                    that come from streaming platforms. Leaving the artist with
                    the smallest piece of the pie and little or no control over
                    their creation and compensation model. Halleluiah gives
                    copyright owners imaginary freedom and invites artists to be
                    part of a paradigm shift that is creating a new royalty
                    system on web3 which compensates musicians fairly. It is up
                    to the artists to decide how many times their song will be
                    played in the metaverse and the sum they will get paid every
                    time their song is played. Oh! The payment from their
                    copyrights always goes to them first. Your song, your money.
                  </p>
                </Collapsible>
                <Collapsible
                  header="How do I register my copyrights and know how much they will be worth?
"
                >
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    We will guide you through this delicate process according to
                    the vast amount of available information.
                  </p>
                </Collapsible>
                <Collapsible
                  header="What is the Metaverse, in simple terms?
"
                >
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    The metaverse is a public virtual space in which avatars can
                    interact with each other for work, study, or play. Fortnite
                    and Minecraft are off-chain metaverses. This means they are
                    not on the blockchain like The Sandbox and Decentraland, for
                    example.
                  </p>
                </Collapsible>
                <Collapsible
                  header="What are avatars?
"
                >
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    It means ”descent" in Sanskrit. Nowadays, the term refers to
                    a representation of a user with characters of
                    his/her/them/its liking in a virtual environment.
                  </p>
                </Collapsible>
                <Collapsible header="Are NFTs worth it? I'm afraid that NFTs are not safe.">
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    We hear you! <br />
                    When people started using the internet in 1993, many had
                    worries and doubts. It's normal, but it's insane to imagine
                    our lives without it. Even Bill Gates had to explain to
                    David Letterman in 1995 that the internet was more valuable
                    than the radio. You are very lucky to be among the very
                    first to explore! NFTs only serve as vessels for copyrights
                    so that your music can be played outside the known borders
                    of the overpopulated internet, aka, the web2. We use
                    blockchain technology to transparently bring existing music
                    to new spaces, creating a new royalty system controlled by
                    the copyright owner, which means a new revenue stream for
                    artists. Halleluiah!
                  </p>
                </Collapsible>
                <Collapsible
                  header="
Can I make an NFT of someone else's music?
"
                >
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    Yes, you can. With the help of our team, you'll be able to
                    relive any legendary copyrighted song you own. Everyone who
                    has already shifted to the Metaverse and those who will join
                    this new and groundbreaking technology will be able to
                    experience your art. Even The Beatles couldn't imagine it!
                  </p>
                </Collapsible>
                <Collapsible
                  header="
So do I sell my rights?
"
                >
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    No, you don’t. You will ALWAYS be the owner of the
                    copyrights. You’re only generating a new income from a new
                    territory using the same music. C’est tout!
                  </p>
                </Collapsible>
              </div>
            </Collapsible>

            <Collapsible header="Collectors Ask">
              <div className="mr-4">
                <Collapsible
                  header="How does music work on NFT at Halleluiah for collectors?
"
                >
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    The song can only be played in the public space of the
                    metaverse by Music NFT's collectors. Collectors can now not
                    only listen to music with earphones/headsets, but also
                    broadcast it in their metaverse space without fear of being
                    fined or having their content censored due to infringement
                    on copyright licenses. Halleluiah!
                  </p>
                </Collapsible>
                <Collapsible
                  header="What's a PlayBank?
"
                >
                  <p className="text-[.7rem] leading-[1.8] sm:text-[1rem]">
                    With Halleluiah, copyright owners can decide how often their
                    songs will be played in the Metaverse. One can limit the
                    number of metaverse play counts to 1,000. After 1,000 plays,
                    your song goes Platinum, which is no longer available in the
                    metaverse. The music industry shifted from the web2 standard
                    model to web3, becoming legendary. Your Music NFT shines
                    brighter than ever. Halleluiah!
                  </p>
                </Collapsible>
              </div>
            </Collapsible>
          </div>
          <p className="text-[.8rem] leading-[1.7] sm:text-base">
            Do you have any further questions?
            <br />
            Feel free to send them to us through our social media.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
