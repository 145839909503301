import HightlightCard from "components/HightlightCard";
import flow from "assets/images/flow.png";
import flowBg from "assets/images/flowBg.png";
import highlightBlob from "assets/images/highlight-blob.png";
import purpleBlobLeft from "assets/images/highlight-text-blob-purple.png";
import greenBlobRight from "assets/images/highlight-text-blob-green.png";
import CricleCard from "components/CricleCard";
import circle2img from "assets/images/circle2img.png";
import circle3img from "assets/images/circle3img.png";
import circle4img from "assets/images/circle4img.png";
import halleluiahTextLogo from "assets/images/halleluiah-text-logo.png";
import toLeftBandingArrow from "assets/images/to-left-banding-arrow.png";
import toLeftBottomBandingArrow from "assets/images/to-left-bottom-banding-arrow.png";

function Highlights() {
  return (
    <div>
      <div className="relative">
        <div className="container" data-aos="fade-up" data-aos-duration="800">
          <div className="lg:mt-0 mt-10 mb-200px relative">
            <h1 className="text-center desc max-w-[52.875rem] mx-auto">
              With Halleluiah marketplace, you can collect NFT’s of your
              favorite songs and unmute any space in the metaverse.{" "}
            </h1>
          </div>
        </div>

        <img
          src={purpleBlobLeft}
          className="absolute w-[50%] sm:w-auto left-0 top-[50%] translate-y-[-50%] z-[-10]"
          alt=""
        />
        <img
          src={greenBlobRight}
          className="absolute w-[50%] sm:w-auto right-0 top-[50%] translate-y-[-50%] z-[-10]"
          alt=""
        />
      </div>

      <div className="mb-20 sm:mb-32 md:mb-52">
        <div className="container">
          <div className="relative">
            <img
              src={highlightBlob}
              className="w-full h-full top-[-3rem] left-0 absolute z-[-10]"
              alt=""
            />
            <h1
              className="title mb-8 sm:mb-12"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Company’s Highlights
            </h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <HightlightCard
                title="NFT's marketplace for music copyrights"
                desc="Through the marketplace, copyright owners can check-in with their own copyrights and check-out with an NFT that offers game-changing utility. Halleluiah!"
              />
              <HightlightCard
                title="A built-in utility"
                desc="Halleluiah's NFTs have a unique utility, they all have a Play Bank. Only holders of the NFT can play the music in the metaverse. Halleluiah!"
              />
              <HightlightCard
                title="A new migration pattern"
                desc="Our solution is a brand new migration pattern for the global music copyrights library. From web 2.0 complex to web 3.0 simple and transparent. Halleluiah!"
              />
              <HightlightCard
                title="A formidable team"
                desc="Halleluiah welcomed in some of the most skilled people from IP, music copyrights, and Web 3.0 game development. Together, we are ready to unmute metaverse life. Halleluiah!"
              />
              {/* <HightlightCard
              title="Publisher to the universe"
              desc="Avatars can utilize Halleluiah Publishing to represent their metaverse work in the universe, so it can surface in Tiktok, Instagram etc. Halleluiah!"
            />
            <HightlightCard
              title="Multiply dedicated teams"
              desc="Halelluiah brought in some of the most skilled people from IP, music copyrights and  Web 3.0 game development. Together we are ready to go. Halleluiah! "
            /> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="relative">
        <img
          src={flowBg}
          className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[-1] w-[100vw]"
          alt=""
        />
        <div className="container relative">
          <img src={flow} className="m-auto w-[100vw] md:w-[50vw]" alt="" />
        </div>
      </div> */}

      <div className="relative">
        <img
          src={flowBg}
          className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[-1] w-[100vw]"
          alt=""
        />

        <div className="container">
          <div className="max-w-[50rem] mx-auto w-full flex justify-center relative space-x-[2%]">
            <img
              src={toLeftBandingArrow}
              className="absolute bottom-[100%] right-[16%] w-[32%]"
              alt=""
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="800"
            />
            <img
              src={toLeftBottomBandingArrow}
              className="absolute top-[100%] left-[16%] w-[32%]"
              alt=""
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="800"
            />
            <CricleCard
              bgImg={circle2img}
              className="flex flex-col items-center justify-center "
            >
              <img className="w-[90%] mb-3" alt="" />
              <p className="text-center text-white text-[2vw] sm:text-sm lg:text-base font-[700]">
                METAVERSE <br />
                STUDIO
              </p>
            </CricleCard>
            <CricleCard
              bgImg={circle3img}
              className="flex flex-col items-center justify-center text-center"
            >
              <img src={halleluiahTextLogo} className="w-[90%] mb-3" alt="" />
              <p className="text-center text-white text-[2vw] sm:text-sm lg:text-base font-[700]">
                NFT <br />
                MARKETPLACE
              </p>
            </CricleCard>
            <CricleCard
              bgImg={circle4img}
              className="flex flex-col items-center justify-center "
            >
              <p className="text-center text-white text-[2vw] sm:text-sm lg:text-base font-[700]">
                MUSIC
                <br /> IPS
              </p>
            </CricleCard>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Highlights;
